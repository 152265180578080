import { RightArrowIcon } from "Icons";
import { Component } from "react";
import { toast } from "react-toastify";
import isEmail from "validator/lib/isEmail";
import "./_homeInput.scss";
import { mobileCheck } from "Utils/ReusableFunctions";

class HomeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isMobile: mobileCheck(),
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      if (this.props.status === "error") {
        toast.error("Please enter a valid email address");
      } else if (this.props.status === "success") {
        toast.success("You are now on the waitlist", { autoClose: 5000 });
      }
    }
  }
  changeEmailValue = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  changeEmailKeyUp = (e) => {
    if (e.key === "Enter") {
      this.subscribeToWaitList();
    }
  };
  subscribeToWaitList = () => {
    let tempMail = this.state.email;
    if (tempMail) {
      tempMail = tempMail.toLowerCase();
    }
    if (isEmail(tempMail)) {
      this.props.subscribe({
        EMAIL: tempMail,
      });
    } else {
      toast.error("Please enter a valid email address");
    }
  };

  render() {
    if (this.state.isMobile) {
      return (
        <>
          <div className="h-p-c-d-c-email h-p-c-d-c-email-mobile">
            <div className="home-page-input-container home-page-input-container-mobile">
              <input
                // disabled={this.props.status === "sending"}
                className="home-page-input"
                value={this.state.email}
                onChange={this.changeEmailValue}
                onKeyUp={this.changeEmailKeyUp}
                style={{
                  fontSize: "16px",
                }}
                placeholder="Email address"
                type="email"
              />
            </div>
          </div>
          <button
            onClick={this.subscribeToWaitList}
            className={`h-p-c-d-c-btn ${
              this.props.status === "sending" ? "h-p-c-d-c-btn-disabled" : ""
            }`}
          >
            <h2 className="h-p-c-d-c-btn-text">Sign up for waitlist</h2>
            <img
              alt="arrow-icon"
              className="h-p-c-d-c-btn-icon"
              src={RightArrowIcon}
            />
          </button>
        </>
      );
    }
    return (
      <div className="h-p-c-d-c-container">
        <div className="h-p-c-d-c-email">
          <div className="home-page-input-container">
            <input
              // disabled={this.props.status === "sending"}
              className="home-page-input"
              value={this.state.email}
              onChange={this.changeEmailValue}
              onKeyUp={this.changeEmailKeyUp}
              placeholder="Email address"
            />
          </div>
        </div>
        <div className="h-p-c-d-c-launch">
          <button
            onClick={this.subscribeToWaitList}
            className={`h-p-c-d-c-btn ${
              this.props.status === "sending" ? "h-p-c-d-c-btn-disabled" : ""
            }`}
          >
            <h2 className="h-p-c-d-c-btn-text">Sign up for waitlist</h2>
            <img
              alt="arrow-icon"
              className="h-p-c-d-c-btn-icon"
              src={RightArrowIcon}
            />
          </button>
        </div>
        <div className="h-p-c-d-c-name-year-container">
          <p className="h-p-c-d-c-name-year">Loch, Inc. © 2024</p>
        </div>
      </div>
    );
  }
}

export default HomeInput;
